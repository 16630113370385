<!-- Localized -->
<template>
    <div class="flex flex-wrap md:flex-row flex-col checkbox-container">
        <div class="form-check checkbox-options flex mt-1 mr-4" v-for="(option, idx) in getOptions" :key="option.id+idx">
            <label>
                <input
                    class="form-check-input appearance-none h-4 w-4 border border-solid ounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    :class="{
                        'border-box-stroke': !error,
                        'border-red-600': error,
                    }"
                    type="checkbox"
                    v-model="getValue"
                    :value="option.id"
                    :id="option.id + 'check' + idx"
                    @input="handleFieldValueChnage"
                    :disabled="disabled || formAlreadySubmitted"
                />
                <span class="form-check-label cursor-pointer inline-block text-gray-800">
                    {{ option.option_label }}
                </span>
            </label>
        </div>
    </div>
</template>

<script>
/* option.id is used instead of option.option_value to avoid wrong behavior in case of same values */

export default {
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
        error: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        formAlreadySubmitted: {
            type: Boolean,
        }
    },
    computed: {
        getOptions() {
            return this.fieldData.options || [];
        },
        getValue: {
            get() {
                return  this.fieldData.value ? this.fieldData.value.map(el => el.id) : [];
            },
            set(newValue) {
                // let data = { value: newValue, field_id: this.fieldData.field_id };
                // if (this.fieldData.multi_id) {
                //     data.multi_id = this.fieldData.multi_id;
                //     delete data.field_id
                // }
                // EventBus.$emit("changeFieldValue", data);
                const result = newValue.map(val => this.getOptions.find(opt => opt.id === val));
                // console.log('result', result);
                this.$emit('input', result);
            }
        },
    },
    methods: {
        handleFieldValueChnage(event) {
            this.$emit('handleFieldValueChnage', event)
        }
    }
};
</script>

<style lang="scss" scoped>
    .checked\:border-blue-600:checked {
        --border-opacity: 1;
        border-color: rgba(37, 99, 235, var(--border-opacity));
    }
    .form-check-input:checked {
        // background-color: #0d6efd;
        // border-color: #0d6efd;
        @apply border-primary bg-primary;
    }
    .form-check-input:checked[type="checkbox"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
    .checked\:bg-blue-600:checked {
        --bg-opacity: 1;
        background-color: rgba(37, 99, 235, var(--bg-opacity));
    }


    
     .form-check input{
        background-color:#F2F7FD;
     }


    @media screen and (max-width:768px){
        .checkbox-container{
            display:flex;
            flex-direction:row;
            width:100% !important;
        }
        .checkbox-container .form-check{
            margin-right: 10%;
        }
    }
</style>